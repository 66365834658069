
/*SVG CHEVRON_SVG*/
/*HEADER*/
.header-list-element svg{
    width: .5em;
    padding: .25em;
    padding-top: .4em;

    fill:var(--black-7);
    -webkit-transition: fill .25s ease-out;
    -o-transition: fill .25s ease-out;
    transition: fill .25s ease-out;
}
.header-list-element:hover svg{
    fill: var(--orange-1);
}
/*SIDEBARMENU*/
.sidemenu-list-element___2{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap row;
            flex-flow: nowrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.sidemenu-list-element svg{
    width: 1.25em;
    height: 1.25em;

    padding-top: 1.5em;
    padding-bottom: 0;
    
    -webkit-transform: rotate(-90deg);
    
        -ms-transform: rotate(-90deg);
    
            transform: rotate(-90deg);

    fill:white;    
    -webkit-transition: fill .25s ease-out, -webkit-transform .25s ease-out;    
    transition: fill .25s ease-out, -webkit-transform .25s ease-out;    
    -o-transition: fill .25s ease-out, transform .25s ease-out;    
    transition: fill .25s ease-out, transform .25s ease-out;    
    transition: fill .25s ease-out, transform .25s ease-out, -webkit-transform .25s ease-out;
}
.sidemenu-list-element:hover svg{
    padding-top: 0em;
    padding-bottom: 1.5em;

    -webkit-transform: rotate(90deg);

        -ms-transform: rotate(90deg);

            transform: rotate(90deg);

    fill: var(--orange-1);
}