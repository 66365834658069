/*      CURSO TARGET        */
.curso-target{
    width: 25em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;

    border-radius: var(--radius);
    background-color: white;
    -webkit-box-shadow: var(--shadow-1);
            box-shadow: var(--shadow-1);

    font-family: "Rubik";

    margin: 1em;
}

.curso-img-principal{
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: var(--radius) var(--radius) 0 0;

    cursor: pointer;
}
.curso-target-info{
    width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.curso-target-info img{
    width: 2em;
    border-radius: 50%;
}
.curso-target-titulo{
    font: 500 1.25em "Rubik";
    text-transform: uppercase;
    padding: .75em 0 .5em 0;
    color: var(--black-8);

    cursor: pointer;
}
.curso-target-titulo:hover{
    color: var(--black-6);
}
.curso-target-docente{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap row;
            flex-flow: nowrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    
    font: 400 .9em "Rubik";
    color: var(--black-6);
}
.curso-target-descripcion{
    padding: .5em 0 2em 0;
    font: 400 1em "Rubik";
    color: var(--black-9);

    height: 2.75em;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: pre-line;
    overflow: hidden;
}
/*      ESTADO      */
.curso-target-estado{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap row;
            flex-flow: nowrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;

    font: 400 1em "Rubik";

    padding-bottom: .5em;
    padding-top: .5em;
}
.curso-estado-element{
    padding: 0 .25em;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap row;
            flex-flow: nowrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.curso-estado-element svg{
    width: 1em;
    height: 1em;
    
}
.estado-curso-pendiente{
    color: var(--advertencia);
    fill: var(--advertencia);;
}
.estado-curso-encurso{
    color: var(--satisfactorio);
    fill: var(--satisfactorio);;
}
.estado-curso-finalizado{
    color: var(--black-2);
    fill: var(--black-2);;
}

/*      precio      */
.curso-estado-precio{
    padding: .5em .75em;

    font: 500 1em "Rubik";
    color: white;
    background-color: var(--satisfactorio);

    border-radius: var(--radius);

    cursor: pointer;
}