
.certificado-container{
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;

    padding: 2em 0;
}

.certificado-container button{
    margin: 1.5em 0 0 0em;
}

/*      PDF READER      */
.react-pdf__Document{
    width: 50% !important;
    height: 50% !important;
    -webkit-box-shadow: var(--shadow-1);
            box-shadow: var(--shadow-1);
}
.react-pdf__Page__canvas{
    width: 100% !important;
    height: 100% !important;
    -o-object-fit:contain;
       object-fit:contain;
}
.react-pdf__Page__textContent{
    display: none;
}

@media screen and (max-width: 900px) {
    .react-pdf__Document{
        width: 75% !important;
        height: 75% !important;
    }   
    .certificado-container button{
        font-size: 1em;
    }
}
@media screen and (max-width: 550px) {
    .react-pdf__Document{
        width: 90% !important;
        height: 90% !important;
    }   
    .certificado-container button{
        font-size: .85em;
    }
}