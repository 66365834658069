@import "../theme/variables";

.cotizacion-page {
    .title-seccion-cotizacion {
        margin-bottom: 2em;
        flex-flow: nowrap column !important;

        & > h3 {
            font-size: 1.5em;
            font-weight: 400;
            margin-bottom: 0.5em;
        }
        &>span{
            text-align: justify;
        }
    }
    & > div > * {
        margin-bottom: 1em;
    }

    & > div {
        width: 100%;
        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: center;

        & > div {
            width: calc(50% - 0.5em);
            padding: 0.25em 0 !important;

            &:first-child {
                margin-right: 0.5em;
            }
            &:last-child {
                margin-left: 0.5em;
            }
            &:only-child {
                width: 100%;
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    .policy-budget {
        & > input[type="checkbox"] {
            width: 1.5em;
            height: 1.5em;
        }
        & > span {
            width: calc(100% - 1.5em);
            padding-left: 0.5em;

            & > a:hover {
                border-bottom: 1px solid $orange-red;
            }
        }
    }

    & > button {
        margin-top: 0.75em;
    }

    .sended-cotizacion{
        display: flex;
        flex-flow:  nowrap column;
        align-items: center;
        justify-content: center;

        .title-seccion-cotizacion{
            text-align: center;

            h3{
                //margin-bottom: 1em;
            }
        }

        a{
            width: 4em;
            height: 4em;
            fill: #3b5998;
        }
    }

    @media screen and (max-width: $tablet-bk) {
    }
    @media screen and (max-width: $mobile-bk) {
        & > div {
            flex-flow: nowrap column;

            & > div {
                width: 100%;
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
        }

        .policy-budget {
            flex-flow: nowrap row !important;
        }
        &>button{
            margin: .75em auto;
        }
    }
}
