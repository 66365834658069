@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-SemiBoldItalic.woff2') format('woff2'),
        url('fonts/Rubik-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-Regular.woff2') format('woff2'),
        url('fonts/Rubik-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-SemiBold.woff2') format('woff2'),
        url('fonts/Rubik-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-MediumItalic.woff2') format('woff2'),
        url('fonts/Rubik-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-Medium.woff2') format('woff2'),
        url('fonts/Rubik-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-Light.woff2') format('woff2'),
        url('fonts/Rubik-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-LightItalic.woff2') format('woff2'),
        url('fonts/Rubik-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-ExtraBold.woff2') format('woff2'),
        url('fonts/Rubik-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-BoldItalic.woff2') format('woff2'),
        url('fonts/Rubik-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-Italic.woff2') format('woff2'),
        url('fonts/Rubik-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-Bold.woff2') format('woff2'),
        url('fonts/Rubik-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-ExtraBoldItalic.woff2') format('woff2'),
        url('fonts/Rubik-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-BlackItalic.woff2') format('woff2'),
        url('fonts/Rubik-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('fonts/Rubik-Black.woff2') format('woff2'),
        url('fonts/Rubik-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

:root{
    /*  COLOR   */
    --orange-1: #f08000;
    --orange-1-rgb: 240,128,0;

    --orange-2: #ff9b28;
    --orange-2-rgb: 255,155,40;

    --orange-3: #fb8500;
    --orange-3-rgb: 251, 133, 0;

    --orange-red: #f15450;
    --orange-red-rgb: 241, 84, 80;

    --gray-1: #313439;
    --gray-1-rgb: rgba(49,52,57,255);

    --light-blue: #8ecae6;
    --light-blue-rgb: 142, 202, 230;

    --blue-green: #219EBC;
    --blue-green-rgb: 33, 158, 188;

    --prusian-blue: #023047;
    --prusian-blue-rgb: 2, 48, 71; 

    --dark-gray: #424E59;
    --dark-gray-rgb: rgb(66, 78, 89);

    --honey-yellow: #FFB703;
    --honey-yellow-rgb: 255, 183, 3;

    --black-1: rgba(0,0,0,0.1);
    --black-2: rgba(0,0,0,0.2);
    --black-3: rgba(0,0,0,0.3);
    --black-4: rgba(0,0,0,0.4);
    --black-5: rgba(0,0,0,0.5);
    --black-6: rgba(0,0,0,0.6);
    --black-7: rgba(0,0,0,0.7);
    --black-8: rgba(0,0,0,0.8);
    --black-9: rgba(0,0,0,0.9);
    --black: rgba(0,0,0,1);

    --white-1: rgba(255,255,255,.1);
    --white-2: rgba(255,255,255,.2);
    --white-3: rgba(255,255,255,.3);
    --white-4: rgba(255,255,255,.4);
    --white-5: rgba(255,255,255,.5);
    --white-6: rgba(255,255,255,.6);
    --white-7: rgba(255,255,255,.7);
    --white-8: rgba(255,255,255,.8);
    --white-9: rgba(255,255,255,.9);
    --white: rgba(255,255,255,1);

    /*      PALETA          */
    --cafe-paleta: #b86200;
    --verde-paleta: #00c123;
    --morado-paleta: #6618F0;
    --violeta-paleta: #4008A3;

    /*    COLORES DE ADVERTENCIA        */
    --advertencia: #e5be01;
    --advertencia-rgb: 229, 190, 1;

    --alerta: #cb6566;
    --alerta-rgb: 203, 101, 102;

    --satisfactorio: #00ac60;
    --satisfactorio-rgb: 0, 172, 96;

    /*   SHADOW     */
    --shadow-1: 0 0px 8px 3px var(--black-1);
    --shadow-2: 0 5px 4px 3px var(--black-1);

    --rp-shadow: 0 0 7px var(--black-2);

    /*  BORDER RADIUS   */
    --radius: 5px;
    --radius-2: 10px;

    /*      BORDER      */
    --border: 1px solid var(--black-1);
    --border-2: 1px solid var(--black-5);

    /*      BG GRADIENT     */
    --bg-gradient: linear-gradient(45deg, #f08000 0%, #f15450 63%);
    --bg-gradient-only2: linear-gradient(45deg, rgba(240,128,0,0) 0%, rgba(241,84,80,1) 63%);
    --bg-gradient-only1: linear-gradient(45deg, rgba(240,128,0,1) 0%, rgba(241,84,80,0) 63%);

    --bg-gradiant-2: linear-gradient(225deg, rgba(2,48,71,1) 50%, rgba(0,91,136,1) 150%);
    --bg-gradient-infinite: linear-gradient(45deg, rgba(240,128,0,1), rgba(241,84,80,1) , rgba(240,128,0,1) , rgba(241,84,80,1) );



}

*{
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    scroll-behavior:smooth;
    box-sizing: border-box;
    font-family: "Rubik";
}
*::selection{
    color: white;
    background: var(--orange-1);
}
*::-moz-selection{
    color: white;
    background: var(--orange-1);
}

/*SCROLLBAR*/
body::-webkit-scrollbar {
    width: .8em;
}
   
body::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0.02);
}
   
body::-webkit-scrollbar-thumb{
    background-color: var(--black-1);
    box-shadow: 0 0 1px 0px var(--black-1);
    border-radius: var(--radius);
}
body::-webkit-scrollbar-thumb:active,body::-webkit-scrollbar-thumb:hover{
    background-color: var(--black-2);
}

#root{
    width: 100%;
}

h1{
    font: 700 2em "Rubik";
    text-align: center;
}
h2{
    font: 600 1.5em "Rubik";
    text-align: center;
}
h3{
    font: 500 1em "Rubik";
    text-align: center;
}

/*  BUTTONS     */
button{
    padding: .75em 1.25em;
    
    font: 600 1.5em "Rubik";
        
    color: var(--orange-1);
    background-color: white;
    border-radius: var(--radius);
    border: 2px solid var(--orange-1);

    cursor: pointer;
}
button:hover{
    color: white;
    background-color: var(--orange-1);
}
button.boton-dark-little{
    padding: .5em 1em;
    margin: 0 auto;
    font-size: 1.2em;
    color: white;
    background-color: var(--orange-1);
    
    transition: background-color .25s ease-out, border-color .25s ease-out;
}
button.boton-dark-little:hover{
    background-color: var(--orange-2);
    border-color: var(--orange-2);   
}
button.boton-little{
    padding: .5em 1em;
    margin: 1em 0 0 0;
    font-size: 1.2em;
    color: white;
    background: var(--bg-gradient);
    border: none;
    transition: background-color .25s ease-out, border-color .25s ease-out;
}
button.boton-little:hover{
    background-color: var(--orange-2);
    border-color: var(--orange-2);   
}
button.disabled{
    background-color: var(--black-1);
    border-color: var(--black-1);
    color: var(--white-2);
}
button.disabled:hover{
    background-color: var(--black-1);
    border-color: var(--black-1);
    color: var(--white-2);
}

/*      SECCIOONES      */
/**/
.container-sup-seccion{
    width: 100%;
    padding: 0 0 0 4em;
    display: flex;
    flex-flow: nowrap column;
    align-items: flex-start;
    justify-content: center;
}
.seccion-sup{
    width: 100%;
}
.seccion-sup>*:first-child{
}
.seccion-sup > div, .seccion-sup>h1{
    padding-left: 1em;
    padding-right: 1em;
}
.seccion-regular{
    width: 100%;
}

.seccion-bg-gradient{
    background: var(--bg-gradient);
}

.seccion-bg-gradient-animation{
    background: var(--bg-gradient-infinite);
    background-size: 800% 800%;
    -webkit-animation: bg-gr 10s ease infinite;
    -moz-animation: bg-gr 10s ease infinite;
    -o-animation: bg-gr 10s ease infinite;
    animation: bg-gr 10s ease infinite;
}
@-webkit-keyframes bg-gr {
    0%{background-position:50% 0%}
    50%{background-position:50% 100%}
    100%{background-position:50% 0%}
}
@-moz-keyframes bg-gr {
    0%{background-position:50% 0%}
    50%{background-position:50% 100%}
    100%{background-position:50% 0%}
}
@-o-keyframes bg-gr {
    0%{background-position:50% 0%}
    50%{background-position:50% 100%}
    100%{background-position:50% 0%}
}
@keyframes bg-gr {
    0%{background-position:50% 0%}
    50%{background-position:50% 100%}
    100%{background-position:50% 0%}
}

/*SECCIONES COLORES*/
.seccion-color-1{
    background-color: white;
}
.seccion-color-2{
    background-color: rgba(0,0,0,0.05);
}
.seccion-color-3{
    background-color: var(--dark-gray);
}

/*          TEXT BG GRADIENT            */
.text-bg-gradient{
    background: var(--bg-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-bg-gradient-hover:hover{
    background: var(--bg-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
