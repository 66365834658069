
.header-component{
    position: sticky;   
    top:0; left: 0; right: 0;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;

    padding: .75em 1em;

    -webkit-box-shadow: var(--shadow-1);

            box-shadow: var(--shadow-1);
    background: var(--bg-gradient);

    z-index: 200;
}

.header-list-component{
    height: 100%;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    font: 400 1.2em "Rubik";
}
.header-list-component li{
    height: 100%;
    text-decoration: none;
    list-style: none;
    padding: 0 .5em;
}
.header-list-component li>a{
    height: 100%;

    text-decoration: none;
    color: var(--white);

    -webkit-transition: color .25s ease-in;

    -o-transition: color .25s ease-in;

    transition: color .25s ease-in;
}
.header-list-component .underline-component{
    width: 100%;
    -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: -webkit-transform .25s ease-out;
    transition: -webkit-transform .25s ease-out;
    -o-transition: transform .25s ease-out;
    transition: transform .25s ease-out;
    transition: transform .25s ease-out, -webkit-transform .25s ease-out;
}
.header-list-element:hover .underline-component{
    display: block;
    background-color: white;
    -webkit-transform: scaleX(100%);
        -ms-transform: scaleX(100%);
            transform: scaleX(100%);
}

@media screen and (max-width: 840px) {
    .header-list-component{
        font-size: 1em;
    }
}

@media screen and (max-width: 550px) {
    .header-list-component{
        display: none;
    }
}

/*HEADER LIST ELEMENT -- 2*/
.header-list-element___2{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap row;
            flex-flow: nowrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}