
.inicio-container-sup-component{    
    position: relative;
    padding-left: 4em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    overflow-y: hidden;

    background: var(--bg-gradient-infinite);
    background-size: 800% 800%;
    -webkit-animation: bg 10s ease infinite;
    animation: bg 10s ease infinite;

}
@-webkit-keyframes bg {
    0%{background-position:50% 0%}
    50%{background-position:50% 100%}
    100%{background-position:50% 0%}
}
@keyframes bg {
    0%{background-position:50% 0%}
    50%{background-position:50% 100%}
    100%{background-position:50% 0%}
}
.inicio-title-component{
    width: 50%;
    font: 700 3.5em "Rubik";
    color: var(--white);
    text-align: left;
    margin-top: 1em;
}
.inicio-subtitulo-component{
    width: 60%;
    font: 400 1.25em "Rubik";
    color: var(--white);
    text-align: left;
    padding: 1em 0;
}
.inicio-contactanos-component{
    background-color: var(--prusian-blue) !important;
    color: white;
    border-radius: 10em;
    border: none;
}
.inicio-img-fondo{
    top: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 105%;
    -o-object-fit: cover;
       object-fit: cover;

    opacity: 0.7;
    z-index: -1;
}

.inicio-sup-buttons{
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 1em;
}


.inicio-sup-buttons>div{
    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: center;
    margin-left: .5em;
}
.inicio-sup-buttons a{
    margin: 0 1em;
    font-size: 1.5em;
    font-weight: 500;
    color: white;

    border-bottom: 2px solid white;

    transition: all .25s ease-in;
    -webkit-transition: all .25s ease-in;
    -moz-transition: all .25s ease-in;
    -ms-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
}

.inicio-sup-buttons>div:hover a{
    color: var(--prusian-blue) !important;
    border-bottom: 2px solid var(--prusian-blue);
}
.inicio-sup-buttons>div:hover div{
    background-color: var(--prusian-blue) !important;
}

.inicio-sup-buttons .underline-component{
    width: calc(100% - 3em);
    transition: all .25s ease-in;
    -webkit-transition: all .25s ease-in;
    -moz-transition: all .25s ease-in;
    -ms-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
    transform:scaleX(100%) !important;
    -webkit-transform:scaleX(100%) !important;
    -moz-transform:scaleX(100%) !important;
    -ms-transform:scaleX(100%) !important;
    -o-transform:scaleX(100%) !important;
}

@media screen and (max-width: 750px) {
    .inicio-title-component{
        width: 85%;
        font-size: 3.5em;
    }
    .inicio-subtitulo-component{
        font-size: 1em;
    }

    .inicio-sup-buttons{
        flex-flow: nowrap column;
        align-items: flex-start;
    }
    .inicio-sup-buttons>div a{
        margin-top: 1em;
        margin-left: 0;
        margin-right: 0;
    }
    .inicio-sup-buttons>div div{
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
    }
    .inicio-sup-buttons .underline-component{
        width: calc(100% - 0em);
    }
}
@media screen and (max-width: 500px){
    .inicio-container-sup-component{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 0;
    }
    .inicio-title-component{
        width: 85%;
        font-size: 3em;
        text-align: center;
    }
    .inicio-subtitulo-component{
        width: 85%;
        font-size: 1em;
        text-align: center;
    }
    .inicio-contactanos-component{
        margin: 1em auto;
    }    
    
}
@media screen and (max-width: 350px) {
    .inicio-title-component{
        font-size: 2.5em;
    }
    .inicio-subtitulo-component{
        font-size: 1em;
    }
}