@import "../theme/variables";

.policy-page {
    
    background-color: $black-1;

    & > div {
        width: 60%;
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 3em 4em 4em 4em;
        margin: 0 auto;

        background-color: white;
        box-shadow: 0 0 10px 5px $black-1;

        img{
            width: 6.5em;
            height: 6.5em;

            position: absolute;
            top: 0em;
            right: 3.5em;
        }

        @media screen and (max-width: $tablet-bk) {
            width: 90%;
        }
        @media screen and (max-width: $mobile-bk) {
            width: 100%;
            padding: 4em 2em;
            img{
                width: 5em;
                height: 5em;
                right: calc(50% - 2.5em);
            }
        }
    }

    h3 {
        margin: 1em 0;
        font-size: 1.25rem;
        font-weight: 400;
    }

    strong {
        margin: 1em 0;

        width: 100%;
        text-align: start;

        font-weight: 500 !important;
    }

    p {
        margin: 0.25em 0;

        width: 100%;
        text-align: justify;
    }
}
