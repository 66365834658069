.servicios-seccion-1{
    padding-bottom: 1em;
}
.titulo-servicios-pagina{
    padding-top: 1em;

    font: 800 2.5em "Rubik";
    color: var(--black-8);
}


.servicio-target{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;

    padding: 2em 0 ;
}
.servicio-target>div:first-child{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;

    -webkit-box-align: start;

        -ms-flex-align: start;

            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    padding: 0 1em;
}
.titulo-servicios{
    width: 100%;
    font: 600 2em "Rubik";
}
.titulo-servicios span{
    background: var(--bg-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}
.descripcion-titulo-servicios{
    width: 100%;
    font: 400 1.25em "Rubik";
    color: var(--black-8);

    padding: .25em 0 1em 0;
}

.servicio-target-left{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.servicio-target-left>div>h1, .servicio-target-left>div>span{
    text-align: left;
}
.servicio-target-right{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
}
.servicio-target-right>div>h1, .servicio-target-right>div>span{
    text-align: right;
}

.img-servicio{
    width: 15em;
    height: 15em;
    margin: 0 5%;

    padding: 1em 0;
}

@media screen and (max-width: 800px) {
    .servicio-target{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin: 1em 0;
        padding: 1.5em 0;
    }
    .img-servicio{
        width: 20em;
        height: 20em;
    }
    .descripcion-titulo-servicios{
        font-size: 1.1em;
    }
}
@media screen and (max-width: 500px) {
    .servicio-target{
        padding: 1em 0;
    }
    .img-servicio{
        width: 15em;
        height: 15em;
    }
}


/*          INDICE       */ 
.servicios-indice{
    padding: .25em 2em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: wrap row;
            flex-flow: wrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.servicios-indice span{
    color: var(--orange-1);
    font-size: 1em;
    font-weight: 1000;
    line-height: .5em;    
}
.servicios-indice-element{
    padding: .5em;
    text-align: center;
    color: var(--black-6);
    font: 400 .8em "Rubik";
    -webkit-transition: ease-out .25s color;
    -o-transition: ease-out .25s color;
    transition: ease-out .25s color;
}
.servicios-indice-element:hover{
    color: var(--orange-1);
}

@media screen and (max-width: 500px) {
    .servicios-indice{
        padding: .25em 1em;
    }
}