/*  COLOR   */
$orange-1: #f08000;
$orange-1-rgb: 240,128,0;

$orange-2: #ff9b28;
$orange-2-rgb: 255,155,40;

$orange-3: #fb8500;
$orange-3-rgb: 251, 133, 0;

$orange-red: #f15450;
$orange-red-rgb: 241, 84, 80;

$gray-1: #313439;
$gray-1-rgb: rgba(49,52,57,255);

$light-blue: #8ecae6;
$light-blue-rgb: 142, 202, 230;

$blue-green: #219EBC;
$blue-green-rgb: 33, 158, 188;

$prusian-blue: #023047;
$prusian-blue-rgb: 2, 48, 71; 

$dark-gray: #424E59;
$dark-gray-rgb: rgb(66, 78, 89);

$honey-yellow: #FFB703;
$honey-yellow-rgb: 255, 183, 3;

$black-1: rgba(0,0,0,0.1);
$black-2: rgba(0,0,0,0.2);
$black-3: rgba(0,0,0,0.3);
$black-4: rgba(0,0,0,0.4);
$black-5: rgba(0,0,0,0.5);
$black-6: rgba(0,0,0,0.6);
$black-7: rgba(0,0,0,0.7);
$black-8: rgba(0,0,0,0.8);
$black-9: rgba(0,0,0,0.9);
$black: rgba(0,0,0,1);

$white-1: rgba(255,255,255,.1);
$white-2: rgba(255,255,255,.2);
$white-3: rgba(255,255,255,.3);
$white-4: rgba(255,255,255,.4);
$white-5: rgba(255,255,255,.5);
$white-6: rgba(255,255,255,.6);
$white-7: rgba(255,255,255,.7);
$white-8: rgba(255,255,255,.8);
$white-9: rgba(255,255,255,.9);
$white: rgba(255,255,255,1);

/*      PALETA          */
$cafe-paleta: #b86200;
$verde-paleta: #00c123;
$morado-paleta: #6618F0;
$violeta-paleta: #4008A3;

/*    COLORES DE ADVERTENCIA        */
$advertencia: #e5be01;
$advertencia-rgb: 229, 190, 1;

$alerta: #cb6566;
$alerta-rgb: 203, 101, 102;

$satisfactorio: #00ac60;
$satisfactorio-rgb: 0, 172, 96;

/*   SHADOW     */
$shadow-1: 0 0px 8px 3px var($black-1);
$shadow-2: 0 5px 4px 3px var($black-1);

$rp-shadow: 0 0 7px var($black-2);

/*  BORDER RADIUS   */
$radius: 5px;
$radius-2: 10px;

/*      BG GRADIENT     */
$bg-gradient: linear-gradient(45deg, #f08000 0%, #f15450 63%);
$bg-gradient-only2: linear-gradient(45deg, rgba(240,128,0,0) 0%, rgba(241,84,80,1) 63%);
$bg-gradient-only1: linear-gradient(45deg, rgba(240,128,0,1) 0%, rgba(241,84,80,0) 63%);

$bg-gradiant-2: linear-gradient(225deg, rgba(2,48,71,1) 50%, rgba(0,91,136,1) 150%);
$bg-gradient-infinite: linear-gradient(45deg, rgba(240,128,0,1), rgba(241,84,80,1) , rgba(240,128,0,1) , rgba(241,84,80,1) );

/*
    SIZES
*/
$padding: 3em;
$padding-tablet: 2em;
$padding-mobile: 1em;

/*
    BREAKPOINTS
*/
$tablet-bk: 1024px;
$mobile-bk: 640px;
