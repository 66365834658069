@import "./variables";

*{
    margin: 0;
    padding: 0;

    font-family: "Rubik" !important;

    box-sizing: border-box;
}

/*
    PADDING 
*/

.px-auto{
    @media screen and (min-width: $tablet-bk) {
        padding-right: $padding;
        padding-left: $padding;
    }
    @media screen and (max-width: $tablet-bk) {
        padding-right: $padding-tablet;
        padding-left: $padding-tablet;
    }
    @media screen and (max-width: $mobile-bk) {
        padding-right: $padding-mobile;
        padding-left: $padding-mobile;
    }
}

.py{
    padding-top: 4em;
    padding-bottom: 4em;
}

.my{
    margin-top: 1em;
    margin-bottom: 1em;
}

/*
    BG COLORS
*/
