
.logo-component{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    cursor: pointer;
}
.logo-component>img{
    height: 3em;
    width: 3em;
    margin-top: -.25em;
    margin-right: .25em;
    padding: 0em 0;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}
.logo-component>span{
    color: var(--white);
}
.logo-component > .logo-data-component{
    font: 700 1.5em "Rubik";    
    margin: 0 .25em;
}
.logo-component > .logo-slogan-component{
    font: 300 1.25em "Rubik";
}

@media screen and (max-width: 760px) {
    .logo-component > .logo-slogan-component{
        display: none;
    }
}

/*  SIDEMENU LIST   */
.sidemenu-component{
    position: fixed;
    top: 0;
    right: .25em;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;

    z-index: 100;
}
.sidemenu-background-component{
    position: absolute;
    background: var(--bg-gradiant-2);

    -webkit-transition: width .5s ease, height .5s ease, margin .5s ease;

    -o-transition: width .5s ease, height .5s ease, margin .5s ease;

    transition: width .5s ease, height .5s ease, margin .5s ease;

    border-radius: 50%;

    z-index: 99;
}
.sidemenu-container-component{
    width: inherit;
    height: 100%;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;

    -webkit-transition: opacity .5s;

    -o-transition: opacity .5s;

    transition: opacity .5s;

    z-index: 102;
}
.sidemenu-icon-component{
    width: 3em;
    height: 3em;
    padding: .5em;

    z-index: 101;
}
.sidemenu-icon-component svg{
    width: 2.5em;
    height: 3em;

    padding-left: .25em;

    fill: var(--white);
}

.sidemenu-container-component>.redes-component{
    margin-bottom: 1em;
}

.sidemenu-list-component{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    list-style: none;;
}
.sidemenu-list-component li{
    padding: 1em 0;
}
.sidemenu-list-component li:hover a{
    color: var(--orange-1);
}
.sidemenu-list-component a{
    padding: .5em 0;

    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    color: white;

    -webkit-transition: color .15s ease-in;

    -o-transition: color .15s ease-in;

    transition: color .15s ease-in;

    font: 800 2em "Rubik";
}

@media screen and (min-width: 551px) {
    .sidemenu-component{
        display: none;
    }
}

/*      REDES COMPONENT     */
.redes-component{
    height: 3em;
}
.redes-component *{
    -webkit-transition: fill .2s;
    -o-transition: fill .2s;
    transition: fill .2s;

    fill: var(--white-5);
}
.redes-component svg{
    width: 2.5em;
    height: 2.5em;
}
.redes-component>ul{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.redes-component>ul>li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    margin: 2em 1em 0 1em;
}
.redes-component a{
    font: 600 1em "Rubik";
    color: var(--white-5);   
    text-decoration: none;
}
.redes-component li:hover a{
    color: var(--orange-1);
}
.redes-component li:hover *{
    fill: var(--orange-1);
}

/*  SLOGAN COMPONENT    */
.slogan-component{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;

    color: white;
    font-family: "Rubik";
    text-align: center;
}
.slogan-component span:nth-child(1){
    font-size: 2em;
    font-weight: 900;
}
.slogan-component span:nth-child(2){
    font-size: 1em;
    font-weight: 400;
}

/*  REDES MIN   */
.redes-min-component{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.redes-min-component svg{
    width: 3em;
    margin: .5em;

    fill: white;
    -webkit-transition: fill .25s ease-out;
    -o-transition: fill .25s ease-out;
    transition: fill .25s ease-out;
}
.redes-min-component>li:hover svg{
    fill: var(--orange-1);
}

/*      LABEL       */
.label{
    padding: .25em;
    font: 500 1em "Rubik";
    color: var(--black-7);
}
.label-1{
    font: 800 3em "Rubik";
}
.label-2{
    font: 700 2em "Rubik";
}
.label-3{
    font: 600 1.5em "Rubik";
}
.label-4{
    font: 500 1em "Rubik";
}
.label-5{
    font: 400 .75em "Rubik";
}
.label-6{
    font: 300 .5em "Rubik";
}
.label-7{
    font: 200 .25em "Rubik";
}


/*      SEPARATOR COMPONENT     */
.separator-component{
    width: 100%;
    height: 2px;
    display: block;
    
    border-top-width: 1px;
    border-top-style: solid;
}

/*      LISTWITHICON        */
.list-icon-component{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap row;
            flex-flow: nowrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    position: relative;
}
.list-icon-list-element{
    padding-top: 1.25em;   

    position: absolute;
    top: 1em;
    
    opacity: 1;     
}
.list-icon-list-in-element{
    padding: 0em;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    visibility: hidden;
    height: 0;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    background-color: white;
    border-radius: 0 0 var(--radius) var(--radius);
    -webkit-box-shadow: var(--shadow-2);
            box-shadow: var(--shadow-2);

    opacity: 0;
    -webkit-transition-delay: .1s;
         -o-transition-delay: .1s;
            transition-delay: .1s;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: .5s;
         -o-transition-duration: .5s;
            transition-duration: .5s;
}
.list-icon-list-element ul>li{
    padding: .25em .5em;
}

.list-icon-component:hover .list-icon-list-in-element{
    visibility: visible;
    opacity: 1;
    height: unset;
    padding: .5em .75em;
}

@media screen and (max-width: 500px){
    .list-icon-component{
        width: 80%;
    }
    .list-icon-list-element{
        padding: .5em;
        padding-left: 4em;

        left: 100%;
        
        opacity: 1;   
    }
    .list-icon-list-in-element{
        background-color: black;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: nowrap column;
                flex-flow: nowrap column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
    .list-icon-list-element ul>li{
        padding: 0em .5em 2em .5em;
    }
    
    .list-icon-component:hover .list-icon-list-in-element{
        visibility: visible;
        opacity: 1;
        height: unset;
        padding: .5em;
    }
    /*x -150%*/
    .list-icon-component:hover * .sidemenu-list-component{
        -webkit-transform: translateX(-150%);
            -ms-transform: translateX(-150%);
                transform: translateX(-150%);
    }

    .volver-sidemenu-extra{
        width: 100%;
        text-align: left;
        font: 800 2em "Rubik";
        color: white;
        padding-left: .5em;

        -webkit-transition: color .25s ease-out;

        -o-transition: color .25s ease-out;

        transition: color .25s ease-out;
    }
    .volver-sidemenu-extra:hover{
        color: var(--orange-1);
    }
    @media screen and (max-width: 500px) {
        .volver-sidemenu-extra{
            display: block;
        }
    }
}

/*       SUBLINE        */
.subline-component{
    display: block;
    width: 50%;
    height: .25em;
    margin: .5em auto;

    background: var(--bg-gradient);
    border-radius: var(--radius);

    border: none;
}

/*      POPUP MENU      */
.popupmenu-component{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.popupmenu-component svg{
    width: 1em;
    fill: black;
}
.popupmenu-component:hover svg{
    fill: var(--black-7);
}
.popup-subcomponent-element{
    position: relative;
}
/*          CONTACTAR BUTTON            */
.contactar-popup-button-component{
    padding: .5em .5em;
    background-color: white;
    -webkit-box-shadow: var(--shadow-1);
            box-shadow: var(--shadow-1);
    border-radius: var(--radius);

    position: absolute;
    top: 1.25em;
    right: 0;
}
.contactar-popup-button-component:hover{
    background-color: var(--orange-1);
}
.contactar-popup-button-component:hover span{
    color: white;
}
.contactar-popup-button-component>span{
    font: 500 1em "Rubik";
    color: var(--black-7);
}
.contactar-popup-button-component{
    cursor: pointer;
}

/**         REDIRECT TO         */
.redirect-to-component{
    width: 1em;
    height: 1em;

    cursor: pointer;
}
.redirect-to-component svg{
    width: 100%;
    height: 100%;
}


/*          INPUT           */
.input-component{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -ms-flex-item-align: start;
        align-self: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;

    padding: .25em;
}
.input-title-component{
    font: 500 .75em "Rubik";
    padding-left: 2px;
}
.dark .input-title-component{
    color: var(--white-5);
}
.light .input-title-component{
    color: var(--black-5);
}

.input-element-component{
    width: 100%;
    padding: .25em 0;
    background-color: transparent;
    
    border-color: transparent;

    font: 600 1em "Rubik";
}

.input-element-component::-moz-color-swatch ,.input-element-component::-webkit-color-swatch, input.input-element-component:focus{
    border-color: transparent;  
    outline: none;
    border: none;
}
input.input-element-component:focus{
    outline: none;
}

.dark .input-element-component{
    color: white;

    border-bottom: 1px solid var(--white-5);    
}
.light .input-element-component{
    color: black;

    border-bottom: 1px solid var(--black-5);
}

/*      TEXT AREA INPUT     */
.textarea-input{

}
.textarea-input textarea{
    margin: .75em 0;;
    min-height: 4em;
    width: 100%;
    border: var(--border-2) !important;
    border-radius: var(--radius);
    -webkit-border-radius: var(--radius);
    -moz-border-radius: var(--radius);
    -ms-border-radius: var(--radius);
    -o-border-radius: var(--radius);
}

/*         SELECT INPUT     */

.select-input-component{
    display: flex;
    flex-flow: nowrap column;
    align-items: flex-start;
    justify-content: center;
}
.select-input-component>select{
    padding: .5em;
    margin: .75em 0;
    font: 400 1em "Rubik";
    border: var(--border);
    box-shadow: var(--shadow-2);
    border-radius: var(--radius);
    -webkit-border-radius: var(--radius);
    -moz-border-radius: var(--radius);
    -ms-border-radius: var(--radius);
    -o-border-radius: var(--radius);

    background-color: white;
    outline: none;
}

/*      NOTIFICACION COMPONENT      */
.notificacion-component{
    display: none;
    width: 100%;
    text-align: center;

    font: 600 1em "Rubik";
    color: var(--orange-1);
}


/*      LOADER          */
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--orange-2);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }



  /*            SPAN TEXT WITH ICON      */
.text-with-icon-component{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-flow: nowrap row-reverse;
            flex-flow: nowrap row-reverse;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;

    font: 400 1em "Rubik";
    color: var(--black-6);  

    padding: .25em 0;
}
.text-with-icon-component svg{
    width: 1em;
    height: 1em;

    min-width: 1em;
    min-height: 1em;

    padding: .5em;
    padding-right: 1em;

    fill: url(#bg-gradient);
    -webkit-box-sizing: unset;
            box-sizing: unset;
}

@media screen and (max-width: 800px) {
    .text-with-icon-component{
        font-size: 1em;
    }
}
@media screen and (max-width: 400px) {
    .text-with-icon-component{
        font-size: .8em;
    }
}


/*      PAGE 404        */
.page-404{
    width: 100%;
    height:  100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    overflow: hidden;
    position: relative;

    margin: auto 0;
    z-index: 0;
}
.page-404>h1{
    width: 100%;
    text-align: center;

    font: 800 5em "Rubik";
}
.page-404>span{
    width: 100%;
    text-align: center;

    font: 400 1.25em "Rubik";
    margin: 0 .5em 2em .5em;
}

/*      NUBES       FONDO       */
.nubes{
    display: block;
    position: absolute;
    z-index: -1;
}
.nubes img{
    width: 100%;
    display: block;
    position: absolute;

    -o-object-fit: contain;

       object-fit: contain;

    -webkit-filter: drop-shadow(0 0 5px rgba(0,0,0,0.075));

            filter: drop-shadow(0 0 5px rgba(0,0,0,0.075));
}
.x1{
    height: 75%;
    bottom: 25%;
    left: 0%;
    opacity: .4;
    -webkit-animation: nubmove 120s infinite;
            animation: nubmove 120s infinite;
}
.x2{
    height: 50%;
    bottom: 20%;
    left: -25%;
    opacity: .4;
    -webkit-animation: nubmove 60s infinite;
            animation: nubmove 60s infinite;
}
.x3{
    height: 30%;
    bottom: 15%;
    left: 25%;
    opacity: .5;
    -webkit-animation: nubmove 40s infinite;
            animation: nubmove 40s infinite;
}
.x4{
    height: 25%;
    bottom: 10%;
    left: 35%;
    opacity: .6;
    -webkit-animation: nubmove 50s infinite;
            animation: nubmove 50s infinite;
}
.x5{
    height: 15%;
    bottom: 20%;
    left: -10%;
    opacity: .7;
    -webkit-animation: nubmove 30s infinite;
            animation: nubmove 30s infinite;
}
@-webkit-keyframes nubmove{
    from{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
    to{
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}
@keyframes nubmove{
    from{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
    to{
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}
@media screen and (max-width: 500px) {
    .x4, .x5{
        display: none;
    }
}

/*      SERVICIOS CARDS       */
.servicios-component{
    padding: 2em 0;
    margin: 0em 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: wrap row;
            flex-flow: wrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

/*      CARD SERVICIO           */
.card-servicio-comp{
    width: 20em;
    margin: -10em .5em 11em .5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;

    border-radius: var(--radius);
    -webkit-box-shadow: var(--shadow-1);
            box-shadow: var(--shadow-1);
    background-color: var(--white);
    z-index: 5;
}
.card-servicio-img{
    cursor: pointer;
    width: inherit;
    position: relative;
}
.card-servicio-img>img{
    display: block;
    width: inherit;
    height: 100%;

    border-radius: var(--radius) var(--radius) 0 0;
}
.card-servicio-img>.card-img-text{
    position: absolute;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: inherit;
    height: 100%;
    border-radius: var(--radius) var(--radius) 0 0;
    -webkit-transition: background-color .25s ease-out;
    -o-transition: background-color .25s ease-out;
    transition: background-color .25s ease-out;
}
.card-img-text>a.card-hover-text{
    cursor: pointer;
    font: 700 1.5em "Rubik";
    text-transform: uppercase;
    color: white;
    opacity: 0;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -webkit-transition: opacity .15s ease-out, -webkit-transform .25s ease-out;
    transition: opacity .15s ease-out, -webkit-transform .25s ease-out;
    -o-transition: transform .25s ease-out, opacity .15s ease-out;
    transition: transform .25s ease-out, opacity .15s ease-out;
    transition: transform .25s ease-out, opacity .15s ease-out, -webkit-transform .25s ease-out;
}
.card-servicio-img:hover .card-img-text{
    background-color: var(--black-3);
}
.card-servicio-img:hover .card-img-text>a.card-hover-text{
    opacity: 1;
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
}
.card-servicio-datos{
    width: inherit;
    height: 10em;
    padding: 1em;
}
.card-skill-datos{
    font: 700 .7em "Rubik";
    display: block;
    text-align: left;
}
.card-titulo-servicio-datos{
    font: 400 1.5em "Rubik";
    text-align: left;
    display: block;
    margin-bottom: 1em;
}
.card-descripcion-servicio-datos{
    font: 200 .9em "Rubik";
    text-align: left;
    display: block;
}



/*          SLIDES          */
.container-slide{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-top: 2em;
}
.container-slide>img{
    width: 20em;
    border-radius: var(--radius);
    -webkit-box-shadow: var(--shadow-1);
            box-shadow: var(--shadow-1);
    z-index: 5;
}
.container-slide>div{
    width: 40em;
    margin: -3em 1em 2em 1em;
    padding: 4em .5em 1em .5em;

    background: var(--white);
    border-radius: var(--radius);
    -webkit-box-shadow: var(--shadow-1);
            box-shadow: var(--shadow-1);

    z-index: 4;
}
.datos-slides{
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 1em;;
}
.datos-slides>.titulo-slide{
    display: block;
    text-align: center;
    color: var(--black-7);
    font: 500 1.5em "Rubik";
}
.datos-slides>.skill-slide{
    display: block;
    text-align: center;
    color: var(--black-6);
    font: 300 .9em "Rubik";
}
.mas-info-slides{
    padding: 0 1em;
}

@media screen and (max-width: 700px) {
    .container-slide>div{
        width: 30em;
    }
}
@media screen and (max-width: 550px) {
    .container-slide>div{
        width: 25em;
    }
}
@media screen and (max-width: 450px) {
    .container-slide>div{
        width: 20em;
    }
    .container-slide>img{
        width: 15em;
    }
}
@media screen and (max-width: 350px) {
    .container-slide>div{
        width: 15em;
    }
    .container-slide>img{
        width: 13em;
    }
}

.swiper-pagination{
    position: unset !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-flow: wrap row !important;
            flex-flow: wrap row !important;
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
    width: 100% !important;
    margin-bottom: 0em;
}
.swiper-pagination-bullet-active{
    background: var(--bg-gradient) !important;
}
.swiper-pagination-bullet-active a{
    color: white !important;
    font: 500 .8em "Rubik";
}

.bullets-slides{
    background-color: white !important;
    -webkit-box-shadow: var(--shadow-2);
            box-shadow: var(--shadow-2);
    opacity: 1 !important;
    margin: .25em .25em !important;
}
.bullets-slides a{
    color: var(--black-6);
    font: 400 .8em "Rubik";
    
    line-height: 2em;
}
.swiper-pagination>div{
    width: unset !important;
    border-radius: 10em;
    height: inherit;
    padding: 0 .5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.swiper-slides-component{
    display: flex;
    flex-flow: nowrap column-reverse;
}

.btn-slides{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    margin-top: 1em;
}

/*          CONTACTANOS COMPONENT       */
.contactanos-component{
    background-color: var(--gray-1);
    font: 600 1em "Rubik";
    border: none;
    color: white;
    border-radius: 10em;
    -webkit-border-radius: 10em;
    -moz-border-radius: 10em;
    -ms-border-radius: 10em;
    -o-border-radius: 10em;

    cursor: pointer;
}
.contactanos-component:disabled{
    background: var(--black-2);
    cursor: default;
}
.contactanos-component:disabled:hover{
    background: var(--black-2);
}
.contactanos-component:hover{
    background: var(--bg-gradient);
}


/*      EMPRESAS COLABORADORAS      */

.empresas-contactos{
    
}
.titulo-empresas-contactos{
    display: block;
    width: 100%;
    text-align: center;
    text-transform: uppercase;

    font: 400 1.25em "Rubik";
    color: var(--black-7);

    padding: 1em 0 0em 0;
}

.empresas-portafolio-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: wrap row;
            flex-flow: wrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.card-empresa a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    margin: 1em;
}

.card-empresa img{
    -webkit-filter:grayscale(10);
            filter:grayscale(10);
    -webkit-transition: -webkit-filter .15s ease-out;
    transition: -webkit-filter .15s ease-out;
    -o-transition: filter .15s ease-out;
    transition: filter .15s ease-out;
    transition: filter .15s ease-out, -webkit-filter .15s ease-out;
}

.card-empresa:hover img{
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}

/*      VERTICAL LINE       */

.vertical-line-component{
    display: block;
    height: 6em;
    width: 3px;

    border-radius: 100em;
    background: var(--bg-gradient);
}

/*          CONTACTOS CARDS     */
.contactos-medios-component{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: wrap row;
            flex-flow: wrap row;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.datos-contactos-medios{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 32%;
            flex: 1 1 32%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.datos-contactos-medios>svg{
    width: 5em;
    height: 5em;

    fill:url(#def-gradient-svg);
}

.datos-titulo-contactos-medios{
    font: 600 1em "Rubik";

    padding-bottom: .5em;
}
/**/
.datos-text-container-contactos{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap row;
            flex-flow: nowrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: .25em .5em;

    cursor: pointer;
}
.datos-text-container-contactos>svg{
    width: .9em;
    height: .9em;
    display: block;
    fill: url(#def-gradient-svg);

    margin: 0 .5em;
}
.datos-text-contactos-medios{
    font: 300 .9em "Rubik";
    padding: .25em 0;

    max-width: calc(100% - calc(.9em + 1em + .5em));
    
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}

/**/
.datos-url-container-contactos{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap row;
            flex-flow: nowrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: .25em .5em;

    cursor: pointer;
}
.datos-url-container-contactos>svg{
    width: .8em;
    height: .8em;
    fill: url(#def-gradient-svg);

    margin: 0 .5em;
}
.datos-url-contactos-medios{
    font: 300 .9em "Rubik";
    padding: .2em 0;

    
    color: black;
}

@media screen and (max-width: 800px) {
    .contactos-medios-component{
        -ms-flex-pack: distribute;
            justify-content: space-around;
    }
    .contactos-medios-component>.vertical-line-component:nth-child(4){
        height: 3px;
        width: calc(100% - 4em);
        margin: .5em 0;  
    }
    .datos-contactos-medios{
        -webkit-box-flex: 1;
            -ms-flex: 1 1 45%;
                flex: 1 1 45%;
        padding-bottom: 1em;
    }
    .datos-text-container-contactos, .datos-url-container-contactos{
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 0 2em;
    }
}

@media screen and (max-width: 400px) {

    .contactos-medios-component>.vertical-line-component{
        height: 3px;
        width: 70%;
        margin: .5em 0;    
    }
    
}


/*      BENEFICIOS COMPONENT        */
.beneficios-software-component{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap row;
            flex-flow: nowrap row;
    -webkit-box-align:stretch;
        -ms-flex-align:stretch;
            align-items:stretch;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    
    padding: 1em 0;
    margin-top: -8em;
    height: calc(100% - 8em);
}
.beneficios-software-right{
    width: 60%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: wrap row;
            flex-flow: wrap row;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.target-beneficio-software{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 calc(50% - 2em);
            flex: 1 1 calc(50% - 2em);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;

    margin: .75em .25em;
    padding: .75em;

    border-radius: var(--radius);
    
    -webkit-transition: all .25s ease-out;
    
    -o-transition: all .25s ease-out;
    
    transition: all .25s ease-out;
}
.target-beneficio-software:hover{
    -webkit-box-shadow: var(--shadow-1);
            box-shadow: var(--shadow-1);
}

.target-beneficio-software>span{
    font: 500 1em "Rubik";
    color: var(--black-7);
}
.target-beneficio-software>p{
    font: 300 .9em "Rubik";
    padding-top: .5em;
    line-height: 1.25em;
}


.beneficios-software-left{
    width: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -ms-flex-pack: distribute;
        justify-content: space-around;

    padding: 1em 1.5em;
    border-radius: var(--radius);
}
.beneficios-software-left>h1{
    display: block;
    width: 100%;
    text-align: right;

    font: 700 2.5em "Rubik";
    color: white;
}

@media screen and (max-width: 850px) {
    .beneficios-software-component{
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
        padding: 0;
    }
    .beneficios-software-component>div{
        width: 100%;
    }
    .beneficios-software-right{
        padding: 1em;
    }
    .beneficios-software-left{
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        border-radius: var(--radius) var(--radius) 0 0;
    }
    .beneficios-software-left>h1{
        text-align: center;
    }
}
@media screen and (max-width: 500px){
    .target-beneficio-software{
        -webkit-box-flex: 1;
            -ms-flex: 1 1 100%;
                flex: 1 1 100%;
        margin: .5em 0;
    }
}

/*      In HEADEr LANGUAGE TRANLATOR        */
.change-language-menu{
    position: relative;

    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}
.change-language-menu>span{
    text-transform: uppercase;
    color: white;
    font-weight: 100;
    font-size: .75em;
}
.change-language-menu>svg{
    color: white;
    fill: white;
    height: 1.25em;
    width: 1.25em;
}
.change-language-menu>svg:hover ~ ul{
    display: flex;
}
.change-language-menu>span:hover ~ ul{
    display: flex;
}
.change-language-menu>ul{
    display: none;

    position: absolute;
    right: 0;
    top: 1rem;

    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: center;

    padding-top: 1rem;

    
}
.change-language-menu>ul:hover{
    display: flex;
}
.change-language-menu>ul>li{
    padding: .5rem 1rem;
    cursor: pointer;
    background-color: var(--orange-red);
    text-align: center;
    color: white;
}

.change-language-menu>ul>li:first-child{
    padding-top: 1rem;
}
.change-language-menu>ul>li:last-child{
    padding-bottom: .5rem;
}

.change-language-menu>ul>li:last-child{
    border-radius: 0 0 var(--radius) var(--radius);
    -webkit-border-radius: 0 0 var(--radius) var(--radius);
    -moz-border-radius: 0 0 var(--radius) var(--radius);
    -ms-border-radius: 0 0 var(--radius) var(--radius);
    -o-border-radius: 0 0 var(--radius) var(--radius);
}

@media screen and (max-width: 550px) {
    .change-language-menu>ul{
        right: unset;
    }
    .change-language-menu>ul>li{
        background-color: transparent;
    }
}