
.inscribete-page{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    padding: 2em 0;
}

.form-curso-area{
    width: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.form-curso-area>span:first-child{
    font: 400 .9em "Rubik";
    color: var(--black-6);
}
.form-curso-area>.titulo-curso-form-area{
    font: 500 1.25em "Rubik";
    color: var(--black-8);
}

.form-body-inscribete{
    width: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: wrap row;
            flex-flow: wrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;

    background-color: white;
    -webkit-box-shadow: var(--shadow-1);
            box-shadow: var(--shadow-1);
    border-radius: var(--radius);;

    padding: 2em;
    margin: 2em 0;
}
.form-body-inscribete>div{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 calc(50% - 2em);
            flex: 1 1 calc(50% - 2em);
    margin: 1em;
}
.nota-inscribir-form{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 calc(100% - 2em);
            flex: 1 1 calc(100% - 2em);
}
.nota-inscribir-form>span{
    font: 500 1em "Rubik";
    color: var(--black-8);
}
.nota-inscribir-form>span>p{
    font: 300 1em "Rubik";
    color: black;
}
.nota-inscribir-form>span>p>strong{
    font-weight: 400;
}

.subida-archivo-form-inscribir{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    overflow-x: hidden;
}
.subida-archivo-form-inscribir>span:nth-child(1){
    font: 400 1em "Rubik";
}
.subida-archivo-form-inscribir>span:nth-child(2){
    font: 300 .7em "Rubik";
    margin-bottom: .25em;
}
.subida-archivo-form-inscribir>input::before{
    content: "";
}
.subida-archivo-form-inscribir>input::-webkit-file-upload-button{
    background: var(--bg-gradient);
    border: none;
    padding: .5em;
    border-radius: 10em;

    color: white;
    font: 400 1em "Rubik";
}
.subida-archivo-form-inscribir>input::-webkit-file-upload-button *::before{
    content: ""; 
}

@media screen and (max-width: 600px) {
    .form-body-inscribete>div{
        -webkit-box-flex: 1;
            -ms-flex: 1 1 calc(100% - 2em);
                flex: 1 1 calc(100% - 2em);
    }
}
@media screen and (max-width: 400px) {
    .form-body-inscribete{
        width: 90%;
        padding: 1em;
    }
    .form-curso-area{
        width: 90%;
    }
}