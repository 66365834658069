.nosotros-landing{
    padding-bottom: 4em;
}

.mision-vision-nosotros{
    width: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap row;
            flex-flow: nowrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

.datos-nosotros{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    
}
.datos-nosotros>img{
    width: 5em;
    height: 5em;
}
.datos-nosotros>span{
    padding-top: 1em;
}
.datos-nosotros>.subtitulo-nosotros{
    font: 400 2em "Rubik";
    text-transform: uppercase;
    
    width: 100%;
    display: block;
    text-align: center;
}

/**/
.slides-nosotros{
    padding-top: 1em;
    width: calc(100% - 4em);
    background-color: transparent !important;
}
.card-mision-vision-nosotros .titulo-slide{
    text-transform: uppercase;
}
.card-mision-vision-nosotros .skill-slide{
    padding: .5em;
}
.card-mision-vision-nosotros .skill-slide>span{
    font-weight: 500;
    line-height: 2em;
}