
.cursos-component-page{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;   
    -webkit-box-align: center;   
        -ms-flex-align: center;   
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    background-color: rgba(0,0,0,0.05);
}
/*      MINI COMPS*/
.curso-page-supimg{
    width: 100%;
    
}
.curso-page-supimg>div{
    width: 5em;
    position: fixed;
    top: 3.5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    background-color: var(--black-2);
    -webkit-box-shadow: var(--shadow-1);
            box-shadow: var(--shadow-1);

    padding-right: .5em;
    font: 500 1.25em "Rubik";
    color: white;

    cursor: pointer;
}
.curso-page-supimg img{
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.curso-page-supimg svg{
    width: 1em;
    padding: .15em;

    -webkit-transform: rotate(90deg);

        -ms-transform: rotate(90deg);

            transform: rotate(90deg);
    fill: white;
}

.curso-page-supinf{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    font-family: "Rubik";

    padding: .5em 0 2em 0;
}
.curso-page-supinf-titulo{
    text-align: center;
    text-transform: uppercase;
    font: 700 2em "Rubik";

    color: var(--black-7);

    padding: .5em 0 .25em 0;
}
.curso-page-supinf-docente{
    text-align: center;

    font: 400 1em "Rubik";
    color: var(--black-6);

    padding: .25em 0;
}

.curso-page-supinf-descripcion{
    width: 90%;
    display: block;
    text-align: left;
    font: 400 1em "Rubik";

    color: var(--black-5);

    padding: 0em 1em 2em 1em;
}

.curso-page-skills{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    padding: 0 1em;
    color: var(--black-6);

    font: 400 1em "Rubik";
}
.curso-page-skills-desc{
    text-transform: uppercase;

    font-weight: 500;
    color: var(--black-7);
}
.curso-page-skills-skill{
    padding-left: 1em;
    padding-bottom: 1em;
}

/*      VER     */
.cursos-page-ver{
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    position: relative;
}
.cursos-page-supsec{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-flow: nowrap column-reverse;
            flex-flow: nowrap column-reverse;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.curso-page-supcomprar{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-flow:nowrap row;
            flex-flow:nowrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;

    font: 400 1em "Rubik";

    background-color: var(--white);
    -webkit-box-shadow: var(--shadow-1);
            box-shadow: var(--shadow-1);

    margin-top: 1em;
    padding: .5em 0;
}
.curso-page-boton-comprar{
    padding: .75em;
    background-color: var(--satisfactorio);
    border-radius: var(--radius);
    color: white;

    text-transform: uppercase;
    font-weight: 600;

    margin-right: 1em;

    cursor: pointer;
}
.curso-page-boton-comprar:hover{
    -webkit-box-shadow: var(--shadow-1);
            box-shadow: var(--shadow-1);
    background-color: rgba(var(--satisfactorio-rgb), .9);
}
.curso-page-boton-page{
    padding: .75em;
    border-radius: var(--radius);
    border: 2px solid var(--satisfactorio);
    background-color: white;
    color: var(--satisfactorio);
    text-transform: uppercase;
    font-weight: 600;

    margin-right: 1em;

    cursor: pointer;
}
.curso-page-boton-page:hover{
    -webkit-box-shadow: var(--shadow-1);
            box-shadow: var(--shadow-1);
    
}
.curso-page-comprar-precio{
    font-size: 1.25em;
    font-weight: 600;
    color: var(--black-7);

    margin-right: 1em;
}

@media screen and (max-width: 800px) {
    .cursos-page-supsec{
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }
    
}

@media screen and (min-width: 800px) {
    .curso-page-supinf{
        width: 98%;
    }
    .curso-page-supinf span{
        width: 98%;
        text-align: left;
    }
    
    .curso-page-supimg{
        margin-bottom: 0em;
    }

    .cursos-page-ver{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    }
    .curso-page-supcomprar{
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin: 0;
        padding: .5em 0;
        
        border-radius: 0 0 var(--radius) var(--radius);

        position: sticky;
        top:4em;
    }
    .curso-page-supcomprar>*{
        margin: .25em 0;
    }

    .cursos-page-ver{
        margin: 0 2em 0em 2em;
        padding: 0 1em 2em 1em;
        background-color: white;
    }
}