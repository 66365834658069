.title-contactos-sup{
    font: 700 2em "Rubik";
    text-transform: uppercase;

    padding-top: 1em;

    color: var(--black);
}
.subtitle-contactos-sup{
    font: 300 1.5em "Rubik";
    
    padding-top: .5em;
    padding-bottom: 1em;

    color: var(--black-8);
}
.perfiles-seccion-wrap{
    padding: 2em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: wrap row;
            flex-flow: wrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
h3.descripcion-contactos-sup{
    width: 100%;
    margin: 0 auto;

    padding: 2em;

    text-align: justify;

    font: 400 1em "Rubik";
    color: var(--black-8);
}