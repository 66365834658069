

.seccion-regular-formulario{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.seccion-regular-formulario>div{
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    padding-bottom: 2.5em;
}

h1.title-contactos-down{
    font: 600 2.5em "Rubik";
    color: var(--white);

    padding: .25em .5em;

}
span.slogan-contactos-down{
    font: 800 1em "Rubik";
    color: var(--orange-1);

    text-align: center;
    
    padding: 2em .5em 0 .5em;
}

/*      FORM        */
.formulario-contactos{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: wrap row;
            flex-flow: wrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-flex: 2;
        -ms-flex-positive: 2;
            flex-grow: 2;

    padding: 2em 20%;
}
.formulario-contactos .input-component{
    padding: 1.5em 1em;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.formulario-contactos .input-component:last-child{
    width: 100%;
}
.boton-dark-little{
    
}
.formulario-contactos .input-component{
    
}

@media screen and (max-width: 1000px) {
    .formulario-contactos{
        padding: 2em 10%;
    }
}
@media screen and (max-width: 650px) {
    .formulario-contactos .input-component{
        padding: 1em;
    }
}