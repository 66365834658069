footer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    background-color: black;
}

/*          SECCION 1           */

.seccion-1-footer{
    padding: 2em;

    color: white;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.seccion-1-footer li{
    cursor: pointer;
}
.footer-container-areas{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.footer-links{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    padding: 0 1em 2em 1em;
}  
.footer-links .redes-min-component a{
    display: none;
}
.footer-ul{
    margin-top: 1em;

    font: 500 1em "Rubik";
}
.footer-ul li{
    padding: .5em .25em;
}
.footer-ul li a{
    color: white;

    -webkit-transition: color .25s ease-out;

    -o-transition: color .25s ease-out;

    transition: color .25s ease-out;
}
.footer-ul li:hover a{
    color: var(--orange-1);
}
.footer-links-1{
    -webkit-box-flex: 2;
        -ms-flex-positive: 2;
            flex-grow: 2;
}
.footer-container-areas{
    -webkit-box-flex: 2;
        -ms-flex-positive: 2;
            flex-grow: 2;
}
.footer-links-2{
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.footer-links-3{
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
@media screen and (max-width: 600px) {
    footer{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
    .footer-links{
        text-align: center;
        margin-left: 1em;
        margin-right: 1em;
    }
}
@media screen and (max-width: 450px) {
    .footer-container-areas{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}

/*          SECCION 2           */
.seccion-2-footer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;

    padding: 1em;

    color: white;
    font: 400 .8em "Rubik";
}